@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

@navBarHeight: 55px;

.navbar {
	height: @navBarHeight; //4@typeUnit1-2x
}

.global-nav-wrap {
	.navbar-fixed-top {
		z-index: 1031;

		&.no-border {
			border: 0;

			.main-nav {
				border: 0;
			}
		}

		.navbar-fixed-top-disabled & {
			position: absolute;
		}
	}

	.search-cell .input-group {
		.input-group-btn:last-child > .btn {
			margin-left: 0;
		}
	}

	.btn-notify {
		padding: 0 calc(@typeUnit1-4x);
	}
}

.main-nav {
	a, .main-nav-btn {
		&:hover {
			text-decoration: none;
		}
	}

	.glyphicons {
		top: 0;
		vertical-align: middle;
	}

	.table-cell {
		vertical-align: middle;
		padding: 0;
		white-space: nowrap;
		position: relative;
		text-decoration: none;

		a.btn {
			display: block;
			float: left;
			.BoxSizing(border-box);
			white-space: nowrap;
			text-decoration: none;

			.glyphicons {
				font-size: @type18;
			}
		}

		&.vb-menu.open .btn.dropdown-toggle {
			&:before {
				content: " ";
				border-top: none;
				border-bottom: 14px solid rgb(116, 116, 116);
				border-right: 14px solid transparent;
				border-left: 14px solid transparent;
				position: absolute;
				left: 50%;
				margin-left: -14px;
				bottom: -14px;
				z-index: 900;
			}

			&:after {
				content: " ";
				border-top: none;
				border-bottom: 12px solid @color2Lighten05;
				border-right: 12px solid transparent;
				border-left: 12px solid transparent;
				position: absolute;
				left: 50%;
				margin-left: -12px;
				bottom: -13px;
				z-index: 5001;
			}
		}
	}

	.table-cell-label {
		text-transform: uppercase;
		color: @whiteFade50;
	}

	.navbar > .table-cell-fill {
		width: 100% !important;
	}

	.btn {
		min-width: 36px;

		.glyphicons,
		.glyphicon {
			vertical-align: middle;
			top: 0;
		}

		&-admin-nav.active {
			border: none;
		}
	}

	.main-nav-links {
		position: relative;

		> a {
			display: inline-block;
			margin-right: 30px;
			text-shadow: none;
			color: inherit;
		}

		> .pip {
			height: 7px;
			width: 65%;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		&:not(.active):not(:hover) > .pip {
			background-color: transparent !important;
		}
	}

	.utility-section {
		padding: 0;

		&:last-child {
			padding-right: 0;
		}

		.btn {
			.glyphicons,
			.glyphicon {
				font-size: @type18;
			}
		}

		&.table-cell a.btn {
			line-height: 36px;
		}
	}

	.badge {
		.RoundedShape(2px);
		margin-left: calc(@typeUnit1-4x);
		padding: 3px 5px;

		&.required {
			background: @warningColor;
		}

		&.recommended {
			background: rgba(44, 196, 130, 0.65)
		}
	}
}

.btn.main-nav-btn {
	height: 28px;
	width: 28px;
	min-width: 28px !important;
	padding: 0;
	border: none;
	line-height: 1;
	color: inherit;
	font-size: @type14;
	margin-top: 1px;
	.RoundedShape(3px);
	vertical-align: middle;
	.BoxShadow(0 1px 0 @blackFade50);

	@media(min-width:992px){
		padding: 0 calc(@typeUnit3-4x);
		height: 36px;
		min-width: 36px !important;
		width: auto;
	}

	span:not(.glyphicons) {
		line-height: 36px;
	}

	span + span {
		margin-left: calc(@typeUnit1-4x);
	}
}

@media(max-width: 991px) {
	.table-cell.open {
		position: static;
	}
}



/* restructured for Firefox */

.table-cell.open .btn.dropdown-toggle {
	position: relative;
}

.ff-wrap-fix {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 30px;
}

.main-nav .table-cell.open .btn.dropdown-toggle:before,
.table-cell.open .dropdown-anchor.dropdown-toggle:before,
.main-nav .table-cell.open .btn.dropdown-toggle:after,
.table-cell.open .dropdown-anchor.dropdown-toggle:after {
	background: transparent;
	border-top: none;
	content: " ";
	position: absolute;
}

.main-nav .table-cell.open .btn.dropdown-toggle:before,
.table-cell.open .dropdown-anchor.dropdown-toggle:before {
	border-bottom: 14px solid rgb(116, 116, 116);
	border-right: 14px solid transparent;
	border-left: 14px solid transparent;
	left: 50%;
	margin-left: -14px;
	bottom: -14px;
	z-index: 900;

	@media (max-width: @viewPortMedWidth) {
		border-bottom: none;
	}
}

.main-nav .table-cell.open .btn.dropdown-toggle:after,
.table-cell.open .dropdown-anchor.dropdown-toggle:after {
	border-bottom: 12px solid @color2Lighten05;
	border-right: 12px solid transparent;
	border-left: 12px solid transparent;
	margin-left: -12px;
	z-index: 5001;

	.modal-open & {
		z-index: 920;
	}

	@media (max-width: @viewPortMedWidth) {
		border-bottom: none;
	}
}

.dropdown-anchor ~ .dropdown-menu.navdrop-menu {
	right: 15px;
	top: 47px;
}

.dropdown-anchor ~ .dropdown-menu.navdrop-menu-settings {
	left: -58px;
	top: 41px;
}

.main-nav .table-cell.open .btn.dropdown-toggle:after {
	bottom: -13px;
	left: 50%;
}

.table-cell.open .dropdown-anchor.dropdown-toggle:after {
	bottom: 0;
	left: auto;
	right: -6px;
}

.table-cell.open .show-nav.btn.dropdown-toggle {
	&:afer, &:before {
		content: none;
	}
}

/* TODO: more of a notice that these admin and media navbars get very messy
/* because they are not contained in the same wrapper anymore */

.navbar-admin-main .table-cell.open {
	&:after, &:before {
		content: none;
	}
}

.main-nav .table-cell > a .glyphicons {
	font-size: @type18;
}

.main-nav .utility-section .btn .glyphicons,
.main-nav .utility-section .btn .glyphicon
{
	font-size: @type24;
	top: -1px;

	@media(min-width: 992px) {
		font-size: @type18;
		top: 0;
	}
}

.show-nav {
	background: none;
	width: 15px;
	padding: 0;
	.RoundedShape(0);
	margin: 0;
	.BoxShadow(none);

	.icon-bar {
		width: 100%;
		background: @whiteFade50;
		height: 2px;
		display: block;
		margin-bottom: 2px;
	}

	&:hover,
	&.dropup {
		.icon-bar {
			background: #a7e2ff;
		}
	}
}

#mobile-menu {
	display: none;
	padding-left: 0;

	&.menu .icon-bar {
		background: @accentColor;
	}

	> .btn {

		height: 24px;
		width: 24px;
		min-width: 24px !important;
		padding: 2px 0 0;
		line-height: 24px;
		background: none;
		.BoxShadow(none);
		border: none;

		> span {
			margin: 0 0 4px;
			height: 4px;
		}
	}

	&:hover .icon-bar {
		background: #a7e2ff;
	}
}


/* end fixed nav */


.notification-thumb {
	width: 1@typeUnit3x;
}

/* ADMIN NAV BAR */
.main-nav .navbar-admin-main {
	height: auto;
	.BoxShadow(none);
	border-bottom: none;
	display: table;

	div.tab {
		background: fade(@white, 7.5%);
		.RoundedTopShape(calc(@typeUnit1-4x));
		margin-left: 1px;

		&:hover {
			background: #484e52;
		}

		&.active {
			background: #596065;
			border-left: 1px solid @blackFade50;
			border-right: 1px solid @blackFade50;
		}
	}

	&.navbar {
		color: @whiteFade50;
		min-height: @typeUnit3x;
		height: @typeUnit3x;
		margin-bottom: 0;
	}

	.table-cell {
		padding: calc(@typeUnit1-2x) 15px;

		&:last-child {
			padding-right: 0;
		}
	}

	.table-cell-fill {
		/* width: auto doesn't force alignment here */
		width: 100% !important;
	}

	> a {
		display: inline-block;
		color: #acafb2;
		text-shadow: 0 0 2px @blackFade50;
	}

	.sub-cell {
		padding: 0;

		> a {
			color: @white;
			padding: calc(@typeUnit1-2x) 16px;
			margin-right: -1px;  /* hide some subpixel rendering errors */
		}

		@media(max-width: 991px) {

			> a {
				background: rgb(84,87,90);
			}
		}

		@media(min-width:992px) {
			&.active,
			&:hover {
				> a {
					border-bottom: 0;

					&,
					.label {
						color: @white;
					}
				}

				&:after {
					content: '';
					position: absolute;
					left: 1rem;
					right: 1rem;
					bottom: -1px;
					border-bottom: 6px solid @accentColor;
				}

				.caret {
					border-top-color: @white;
				}
			}
		}
	}

	.account-menu,
	.system-control-panel {
		&.sub-cell > a {
			.RoundedShape(3px);
			margin-right: 15px;
		}
	}

	.account-menu {
		.scrolling {

			height: 340px;

			.scroll-container {
				height: 338px;
				padding: 0;
			}
		}
	}

	.system-control-panel.sub-cell > a {
		padding: 0 calc(@typeUnit1-2x);
		line-height: 32px;

		&.active {
			color: @white;
		}

		.glyphicons {
			margin-right: 3px;
		}
	}

	.round-left > a {
		.RoundedLeftShape(3px);
	}

	.round-right > a {
		.RoundedRightShape(3px);
	}
}

.container-fluid-admin-nav {
	background: @adminGray;
	border-bottom: 1px solid @blackFade25;
}

.view-options,
.mobile-visible.view-filters {
	padding-left: 15px;
}

.media-load-in {
	position: relative;
}

.filter-input {
	position: relative;

	.form-control {
		padding-right: calc(@typeUnit1x);
	}
}

.admin {

	.filter-input .input-group .form-control {
		padding-right: 0;
		border-right: 0;
	}

	.combined-toolbar .filter-input .input-group .btn {
		padding: 6px 7px 6px calc(@typeUnit1-2x);
		border-left: 0;
		.BoxShadow(none);
		color: @blackFade25;

		.glyphicons {
			font-size: @type16;
		}
	}
}

.dropdown-menu.navdrop-menu.tmp-mobile-menu {
	li {
		padding: calc(@typeUnit2x) 0 calc(@typeUnit1-2x) 0;
	}

	a {
		color: #5E5E5E;
		font-size: @type18;
		font-weight: bold;
	}
}

.dropdown-menu.navdrop-menu.tmp-mobile-menu
.main-nav .table-cell.documentation-link > a {
	font-size: @type16;
	font-weight: bold;
}

@media (max-width: (@viewPortMedWidth - 1)) {
	.admin .main-nav.navbar-fixed-top {
		margin-bottom: calc(@typeUnit1-2x);
	}

	.main-nav .navbar-admin-main .table-cell-fill a {
		float: none;
	}

	.mobile-hidden {
		display: none;

		&-medium {
			display: none
		}
	}

	#mobile-menu {
		display: table-cell;
	}

	.main-nav .brand-cell {
		width: auto !important;
	}

	.global-nav-wrap .navbar-fixed-top,
	.main-nav.navbar-fixed-top
	{
		position: relative !important;
		top: 0;
	}
}

@media (max-height: @viewPortHeightForFixed - 1) {
	.global-nav-wrap .navbar-fixed-top,
	.main-nav.navbar-fixed-top
	{
		position: relative !important;
		top: 0;
	}
}

@media(min-width: @viewPortMedWidth) {
	.main-nav .utility-section {
		padding: 0 2px;

		&.notification-cell {
			padding-right: 1px;
		}
	}

	.navbar-brand,
	.active a.navbar-brand {
		margin: 4px 25px 0 0;
		padding: 0;
	}

	.show-nav {
		margin: 0 15px 0 0;
	}
}
