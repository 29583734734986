@import (reference) '~rev-less/global/variables';

.root {
	display: block;
	position: relative;
}

.width {
	width: 150px;
}

.tglInput {
	&:checked {
		~.tglBtn .tglBtnTrack::after {
			left: 21px;
		}

		~.tglBtn .tglBtnTrack {
			background-color: #2CA01C;
		}
	}

	&:disabled~.tglBtn .tglBtnTrack {
		opacity: .4;
	}

	&:focus~.tglBtn {
		border-radius: 25px;
		box-shadow: 0 0 0 1pt #0077c5;
	}
}

.tglBtnLabel {
	cursor: pointer;
	display: inline-block;
}

.tglBtn {
	position: relative;
	padding: 0px;
	border: none;
	background: none;
	outline: none;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.tglBtnTrack {
	overflow: hidden;
	background-color: #8D9096;
	border-radius: 25px;
	height: 25px;
	transition: background-color 0.3s;
	border: 1px solid @lightBorder;
	width: 45px;
}

.tglBtnTrack::after {
	content: "";
	position: absolute;
	top: 1px;
	left: .5px;
	width: 23px;
	height: 23px;
	background-color: @white;
	border-radius: 100%;
	z-index: 1;
	transition: left 0.1s ease-in-out;
}

.tglDisabled {
	cursor: default;
}

.visuallyHidden {
	height: 1px;
	width: 1px;
	margin: -1px;
	outline: 0;
	border: 0;
	position: absolute;
	white-space: nowrap;
	clip: rect(0 0 0 0);
}

.check {
	display: block;
	position: absolute;
	top: 0.8em;
	left: 0.5em;
	font-size: 10px;
}
