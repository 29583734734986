@import (reference) "~rev-less/global/variables";
.chkBox {
	padding-left: 5px;

	button {
		height: 21px;
		width: 21px;
		border-radius: 2px;
		border-width: 3px;
		background-color: @accentColor; //hardcoded for now.
		color: @white !important;
	}
}
