@import (reference) "~rev-less/global/mixins";
@import (reference) "~rev-less/global/variables";

@tabPanelBorder: 1px solid @gray30;
@tabPanelPadding: .75rem;

.tab {
	border-radius: 3px 3px 0 0;
	border: 1px solid transparent;
	border-bottom-width: 0 !important;
	bottom: -1px;
	margin-right: .25rem;
	padding: @tabPanelPadding;
	position: relative;

	&.tabInvalid {
		background-color: @warningColor !important;
		color: @white;
	}
}

.tabFixedColor {
	background: @whiteDarken70;
	color: @white;
}

.tabActive {
	border: @tabPanelBorder;
	z-index: 2;
}

.tabActiveFixedColor {
	background: @white;
	color: @black;
}

.tabPanel {
	border-width: 1px;
	border-style: solid;
	border-radius: 0 @defaultBorderRadius @defaultBorderRadius @defaultBorderRadius;
	padding: @tabPanelPadding;
	position: relative;
	z-index: 1;
}

.tabPanelFixedColor {
	background: @white;
	color: @black;
	border: @tabPanelBorder;
}

.tabVerticalFill {
	.AbsoluteFill;
	padding: @tabPanelPadding;
}

.headerIcon {
	margin-right: 5px;
	@media all and (max-width: (@viewPortSmallWidth - 1px)) {
		margin-right: 0;
	}

}

.hideHeaderLabelInSmallDevices {
	@media all and (max-width: (@viewPortSmallWidth - 1px)) {
		display: none;
	}
}
