@import (reference) '~rev-less/global/mixins';
@import (reference) '~rev-less/global/variables';

.header {
	padding: 0 1rem;
	border-bottom: 1px solid @borderColor;
	min-height: 48px;

	&:after { // IE11 align-items center workaround
		content: '';
		min-height: inherit;
	}
}

.headerTitle {
	font-size: @type20;
	margin: 0;
	.WordBreak;
}

.containerFluid {
	composes: containerFluid from '~rev-shared/ui/vbUiToolbar/VbUiToolbar.module.less';
}

.actions {
	composes: buttonContainer from '~rev-shared/ui/vbUiToolbar/VbUiToolbar.module.less';
}