@import "global/variables";
@import "global/mixins";

.mobile-menu {
	.container-fluid {
		padding: 0;
	}

	li .input-group {
		width: 100%;
	}

	.search-cell {
		search-header vb-ui-typeahead-angular {
			display: block;
			padding: 15px;
			width: 100%;
		}
	}
}

.dropdown-menu.navdrop-menu.mobile-menu-admin {
	background: @color1;
	max-height: 50vh;
	overflow-y: auto;

	ul {
		list-style: none;

		a {
			padding: .75rem;
		}
	}

	li {
		a > {
			color: fade(@white, 80%);

			&.active {
				background: @blackFade25;
				color: @white;
			}

			&:hover, &:focus {
				text-decoration: none;
				background: @blackFade10;
				color: @accentLighten30;
			}

			&.notifications span {
				color: @whiteFade25;
			}

		}
	}
}

.dropdown-menu.navdrop-menu.mobile-menu {
	z-index: 10000;
	top: 48px;
	margin: 0 -16px;
	border: 0;
	border-top: 1px solid @black;
	.RoundedShape(0);
	padding: 0;
	left: 0;
	right: 0;

	&.mobile-menu-admin {
		background: @adminGray;
		margin: 0;
	}

	ul {
		padding: 0;
		.RoundedShape(3px);

		&:last-child {
			margin-bottom: 0;
		}

		ul:not(.dropdown-menu) {
			background: fade(@black, 15%);
			.RoundedShape(0);

			li a {
				padding-left: 30px;
			}
		}
	}

	li {
		> a {
			font-size: @type16;
			display: block;
			position: relative;
		}

		&.menu-header > a, a.menu-header {
			&:after {
				.GlyphiconFont;
				content: '\E224';
				position: absolute;
				right: 15px;
				display: inline-block;
				-webkit-font-smoothing: antialiased;
				font-style: normal;
				font-weight: normal;
				line-height: 1;
				vertical-align: middle;
				font-size: 14px;
				color: @whiteFade25;
			}

			&.active:after {
				.Transform(rotate(90deg));
			}
		}

		&.sub-menu-header a {
			padding-top: 5px;
			padding-bottom: 5px;
			text-transform: uppercase;
			color: @whiteFade50;
		}
	}

	[role="button"] {
		color: fade(@white, 80%);
	}

	.panel-group .panel-heading+.panel-collapse .panel-body {
		background: @whiteFade30;
		border-bottom: 1px solid fade(@white, 80%);
	}
}


@media (max-width: 991px){
	.admin {
		.ui-view.container-fluid {
			padding: 0;
		}

		.main-nav.navbar-fixed-top {
			margin-bottom: 0 !important;
		}

		.panel-heading {
			.RoundedShape(0);
		}

		&-subnav {
		    display: none;
	    }

		body& {
			padding-top: 115px;
		}
	}

	.main-nav .table-cell.brand-cell {
		text-align: center;

		> a {
			float: none !important;
			display: inline-block !important;
		}
	}
}